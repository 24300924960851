<template>
  <div class="column" ref="tabLay">
    <div class="top2">
      <div class="row_normal" @click="back">
        <img class="back" src="../../assets/img/course/Icon_Back.png" />
        <div class="go_home">返回</div>
      </div>
    </div>
    <div class="h43"></div>
    <div class="w1302">
      <div class="row">
        <img class="pic" src="../../assets/img/course/Pic_PGS_PT.png" />
        <div class="column_normal mrl37">
          <div class="text">教师系列课程</div>
          <div class="text1 mt37">
            PGS专题游戏训练课程为面向教师的视频课程，授课教师观看并理 解课程
            内容后，即可以独立在室内或室外进行幼儿体育课程。在本 系列课程中融
            入了更多的足球元素，每节课程中足球游戏的占比更 多。本课程围绕着跑
            动、跳跃、敏捷、协调、平衡、力量、物体控 制（手）、物体控制（脚）
            八个主题进行体育教学。
          </div>
          <div class="text1 mt24">
            PGS专题游戏训练课程共计3套，每套课程各8节，第三套课程增 设课程简
            介和课程总结。每节课程 5~8分钟。配以相应的备课教 案，教师可以根据
            园所具体情况在室内或室外开展课程。
          </div>
        </div>
      </div>
      <div class="row mt54">
        <div v-if="label == 0" class="select">宋振军教练课程</div>
        <div v-if="label != 0" class="normal" @click="clickLabel(0)">
          宋振军教练课程
        </div>
        <div v-if="label == 1" class="select">张梁教练课程</div>
        <div v-if="label != 1" class="normal" @click="clickLabel(1)">
          张梁教练课程
        </div>
        <div v-if="label == 2" class="select">张凯教练课程</div>
        <div v-if="label != 2" class="normal" @click="clickLabel(2)">
          张凯教练课程
        </div>
      </div>
      <div class="content">
        <div class="title">全部课程</div>
        <div class="table">
          <el-table
            header-align="center"
            stripe
            header-cell-class-name="header_row_style"
            :data="list"
            style="width: 100%"
            ref="selectTable"
            @selection-change="handleSelectionChange"
          >
            >
            <el-table-column
              :show-overflow-tooltip="false"
              :align="item.align || 'center'"
              v-for="item in columns"
              :key="item.prop"
              :prop="item.prop"
              :width="item.width"
              :label="item.label"
            >
            </el-table-column>
            <el-table-column width="150" align="center" label="教案">
              <template slot-scope="scope">
                <div class="table_btns">
                  <div class="table_btn" @click="handleEdit(scope.row, 'view')">
                    在线预览
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="150" align="center" label="查看">
              <template slot-scope="scope">
                <div class="table_btns">
                  <div class="table_btn" @click="handleEdit(scope.row, 'view')">
                    可查看
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
export default {
  name: "courseInfo",
  components: {},
  data() {
    return {
      label: 0,
      columns: [
        {
          prop: "serial",
          label: "章节",
          width:150
        },
        {
          prop: "name",
          label: "课程名称",
          width:150
        },
        {
          prop: "sexName",
          label: "课程简介",
        },
      ],
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    clickLabel(index) {
      this.label = index;
    },
  },
  mounted() {
    // this.getTyCourse();
    let _this = this;
    window.onscroll = function () {
      _this.handleScroll();
    };
  },

  beforeDestroy() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  overflow-x: hidden;
  align-items: center;
}
.top2 {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: 43px;
  background: #333333;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 154px;
  padding-right: 308px;
  .row_normal {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .back {
    width: 9px;
    height: 15px;
  }
  .go_home {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #696969;
    margin-left: 5px;
  }
}

.w1302 {
  width: 1302px;
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
.pic {
  width: 540px;
  height: 351px;
}
.mrl37 {
  margin-left: 37px;
}
.text {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.mt37 {
  margin-top: 30px;
}
.mt24 {
  margin-top: 16px;
}
.text1 {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.h43 {
  height: 111px;
}
.column_normal {
  display: flex;
  flex-direction: column;
}
.select {
  width: 200px;
  height: 54px;
  background: #00fcd0;
  border: 3px solid #44eddb;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000101;
}
.normal {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 54px;
  background: #cbcbcb;
  border: 3px solid #bfbfbf;
  border-radius: 30px;
  margin-right: 30px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000101;
}
.mt54 {
  margin-top: 54px;
}
.content {
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  .title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #931a19;
    margin-left: 30px;
    margin-top: 43px;
  }
}
.table {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;
}
</style>